* {
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .container {
    max-width: 900px;

  }
  
  .heatmap {
    display: grid;
    grid-gap: 10px;
    align-items: start;
  }
  
  .col {
    display: grid;
    grid-gap: 10px;
  }
  
  .cell {
    height: 22.45px;
    /* width: 100%; */
    position: relative;
    background: hsl(196deg 36% 95%);
  }
  
  .label {
    text-align: center;
    height: 22.45px;
    font-size: 14px;
  }
  
  .vertical {
    max-width: 400px;
    grid-template-columns: repeat(8, 1fr);
  }
  
  .vertical .col {
    flex-direction: column;
  }
  
  .horizontal {
    max-width: 900px;
    grid-template-rows: repeat(8, 1fr);
  }
  
  .horizontal .col {
    display: grid;
    grid-template-columns: repeat(25, 1fr);
  }
  
  .horizontal .cells .label {
    text-align: left;
  }
  
  .tooltip {
  
    position: absolute;
    display: none;
    text-align: center;
    background: #000000B2;
    color: #FFFFFF;
    border: 1px solid #ccc;
    padding: 2px;
    z-index: 1;
    bottom: 125%; 
    right: -20%;
    font-size: 18px;
    border-radius: 5px;
    min-width: 100px;
    min-height: 50;
    
  }
  .tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 7px;
    border-width: 10px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .tooltip span {
   
    text-align: center;
    font-size: 10px;
    font-size: 300px;
  }
  
  .tooltip .count {
    font-size: 16px;
    font-weight: 800;
  }
  
  .cell:hover .tooltip {
    display: block;
  }
  
  .legend {
    margin-top: 30px;
    max-width: 300px;
     
  }
  
  .legend .cell {
   
    width: 100%;
  }
  
  .legend .labels {
    display: flex;
    justify-content: space-between;
    /* margin-top: 5px; */
  }
  
  .legend span {
    font-size: 16px;
  }
  