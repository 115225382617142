/* @import url('https://fonts.cdnfonts.com/css/lufga'); */

@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: "Lufga";
  src: local("Fontspring-DEMO-lufga-medium"),
    url("../src/fonts/arial-cufonfonts/ArialMdm.ttf") format("truetype");
}

@font-face {
  font-family: "Lufgasemibold";
  src: local("Fontspring-DEMO-lufga-semibold"),
    url("../src/fonts/arial-cufonfonts//ArialMdm.ttf") format("truetype");
}

@font-face {
  font-family: "lufgalight";
  src: local("Fontspring-DEMO-lufga-light"),
    url("../src/fonts/arial-cufonfonts//ARIALI.TTF") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: 'Lufga'; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* SCROLLBAR */
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  cursor: all-scroll;
}

.audio-recorder {
  background-color: transparent !important;
  box-shadow: none !important;
  /* background-color: transparent !important;
  box-shadow: none !important;
  border-radius: .2vmax !important;
  border:  1px solid #D3D3D3; */
}

/* .css-zqsi17-MuiInputBase-root-MuiInput-root:before{
  display: none !important;
} */

.react-html5-camera-photo>img,
.react-html5-camera-photo>video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}


iframe {
  /* overflow: hidden !important; */
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}


.css-9ch1t4-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  background-color: gray !important;
  color: white !important;
  opacity: .4;
}

.css-sp3ygc-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.Mui-disabled {
  background-color: gray !important;
  opacity: .4;
  color: white !important;
}

.css-6hp17o-MuiList-root-MuiMenu-list{
   overflow-x: auto !important;
}