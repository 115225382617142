/* WaveAnimation.css */

.wave-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px; /* Adjust the container height as needed */
    position: relative;
  }
  
  .wave {
    width: 100px;
    height: 30px;
    background-color: #3498db; /* Adjust the wave color */
    border-radius: 15px;
    position: absolute;
    animation: waveAnimation 1.5s infinite ease-in-out;
  }
  
  @keyframes waveAnimation {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  /* Adjust the styles and animation duration as needed */
  

  /* WAVE ANIMATION */

  .audio-wave {
    width: 100%;
    height: 150px;
    background-color: #333;
  }